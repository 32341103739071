import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c3ab36',
      dark: '#887725',
      light: '#cfbb5e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#006ba1',
      dark: '#004a70',
      light: '#3388b3',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

export default theme;
